<template>
    <div class="admin-settings-trnaslations">
        <div class="row">
            <div class="col-12">
                <b-card>
                    <GoodDataTable ref="parent_stores" :mode="'local'" :no-serial="true" :columns="columns"
                        :load-local-data="true" />
                </b-card>
            </div>
        </div>
        <b-modal 
            v-model="modalState" 
            size="xl" 
            centered 
            hide-footer 
            title="Update Store Branches"
        >
            <b-form @submit.prevent="saveData">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <b-button-group class="mx-1 my-1">
                                    <b-input placeholder="Search" v-model="searchS" />
                                </b-button-group>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isEmpty(f_selectedStores)" class="col-12">
                        <b-button-group class="mx-1 my-1" v-for="(selectedStore, sk) in f_selectedStores" :key="`sk_${sk}`">
                            <b-button size="sm" variant="outline-dark text-dark">{{ selectedStore.store_name }}</b-button>
                            <b-button size="sm" variant="danger" @click="moveToAvailable(selectedStore)">X</b-button>
                        </b-button-group>
                    </div>
                    <div v-else class="col-12">
                        <div class="alert alert-danger p-2">
                            No Stores
                            <b-button size="sm" variant="danger" @click="searchS = null">Clear Filter</b-button>
                        </div>
                    </div>
                </div>
                <hr v-if="!isEmpty(f_availableStoresForSelected) && !isEmpty(f_selectedStores)" class="my-2" />
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <b-button-group class="mx-1 my-1">
                                    <b-input placeholder="Search" v-model="searchA" />
                                </b-button-group>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isEmpty(f_availableStoresForSelected)" class="col-12">
                        <b-button-group class="mx-1 my-1" v-for="(availableStore, ask) in f_availableStoresForSelected" :key="`ask_${ask}`">
                            <b-button size="sm" variant="outline-dark text-dark">{{ availableStore.store_name }}</b-button>
                            <b-button size="sm" variant="success" @click="moveToSelected(availableStore)">+</b-button>
                        </b-button-group>
                    </div>
                    <div v-else class="col-12">
                        <div class="alert alert-danger p-2">
                            <p>No Stores available</p>
                            <b-button size="sm" class="ml-1" variant="danger" @click="searchA = null">Clear Filter</b-button>
                        </div>
                    </div>
                </div>
                <div class="row d-flex justify-content-end mt-3">
                    <div class="col-auto">
                        <b-button variant="primary" type="submit">Save</b-button>
                    </div>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities';
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'

export default {
    components: {
        GoodDataTable
    },
    data: () => ({
        searchS: null,
        searchA: null,
        modalState: false,
        loading: false,
        errors: [],
        currentStore: {},
        selectedStores: [],
        availableStoresForSelected: []
    }),
    computed: {
        ...mapGetters('store', ['parentStores', 'childStoresByParentId', 'availableStores']),
        columns: {
            get() {
                return [
                    {
                        field: 'store_name',
                        label: 'Store Name',
                        sortable: false,
                    },
                    {
                        label: 'SMS',
                        field: 'action',
                        sortable: false,
                        type: 'dropdown',
                        buttons: [
                            {
                                image: this.$helpers.getIcons('edit'),
                                text: 'Edit',
                                class: 'border-danger',
                                canRender: props => !isEmpty(this.childStoresByParentId(props.id)),
                                action: props => {
                                    this.manageCompanies(props)
                                },
                            },
                            {
                                image: this.$helpers.getIcons('add'),
                                text: 'Add',
                                canRender: props => isEmpty(this.childStoresByParentId(props.id)),
                                action: props => {
                                    this.manageCompanies(props)
                                },
                            },
                        ],
                    },
                ]
            }
        },
        f_selectedStores: {
            get() {
                if(isEmpty(this.searchS)) return this.selectedStores;
                const q = this.searchS.trim().toLowerCase();
                return this.selectedStores.filter(store => {
                    const storeName = store.store_name.toLowerCase();
                    return storeName.includes(q)
                })
            }
        },
        f_availableStoresForSelected: {
            get() {
                if(isEmpty(this.searchA)) return this.availableStoresForSelected;
                const q = this.searchA.trim().toLowerCase();
                return this.availableStoresForSelected.filter(store => {
                    const storeName = store.store_name.toLowerCase();
                    return storeName.includes(q)
                })
            }
        }
    },
    mounted() {
        this.getStores()
    },
    methods: {
        isEmpty,
        ...mapActions('store', [
            'getStores', 'saveStores'
        ]),
        manageCompanies(item) {
            this.currentStore = item
            this.selectedStores = this.childStoresByParentId(item.id)
            this.availableStoresForSelected = this.availableStores.filter(i => i.id != item.id)
            this.modalState = true
        },
        moveToSelected(item){
            this.availableStoresForSelected = this.availableStoresForSelected.filter(i => i.id != item.id)
            if(!this.selectedStores.some(i => i.id == item.id)){
                this.selectedStores.push(item)
            }
        },
        moveToAvailable(item){
            this.selectedStores = this.selectedStores.filter(i => i.id != item.id)
            if(!this.availableStoresForSelected.some(i => i.id == item.id)){
                this.availableStoresForSelected.push(item)
            }
        },
        async saveData(){
            const params = {
                parent_id: this.currentStore.id,
                child: this.selectedStores.map(i => i.id)
            }
            this.loading = true
            await this.saveStores(params)
            this.loading = false
            this.currentStore = {}
            this.selectedStores = []
            this.availableStoresForSelected = []
            this.modalState = false
        }
    },
    watch: {
        parentStores: {
            handler(nv) {
                if (this.$refs.parent_stores) {
                    this.$refs.parent_stores.setLocalData(nv)
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>